import { Link } from "@remix-run/react";
import { DefaultLayout } from "./DefaultLayout";

export const LoginLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <DefaultLayout>
      <div className="mt-6 flex w-full max-w-lg flex-col gap-6 self-center pb-6">
        {children}
        <div className="ml-[50%] w-dvw -translate-x-1/2 border-b border-black" />
        <p className="text-secondaryText px-6 text-center text-sm font-normal">
          Moneta is an AI that enables you to create, use and share web applications conversationally.
          <br />
          <br />
          Moneta is currently in early release (‘alpha’) so expect some rough edges. Please write us at{" "}
          <a href="mailto:feedback@moneta.studio" className="underline">
            feedback@moneta.studio!
          </a>
        </p>
        <div className="flex justify-center">
          <Link
            to="/terms-of-service"
            className="text-primaryText border-b border-black text-base font-normal tracking-tighter"
          >
            Terms of Service
          </Link>
        </div>
      </div>
    </DefaultLayout>
  );
};
